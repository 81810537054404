<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('class_advancement')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('class_advancement')"
                              :isFilter="false"/>
            </template>
            <ValidationObserver ref="form">
                <div class="row mb-5 border align-items-center">
                    <div class="row col-12 pt-4 p-0">
                        <div class="col-12 col-md-7 col-lg-5">
                            <ValidationProvider name="faculty_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group class="col-12" :label="$t('faculty')">
                                    <faculty-selectbox v-model="queryParams.faculty_code" :multiple="true"
                                                       :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="department_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group class="col-12" :label="$t('department')">
                                    <department-selectbox v-model="queryParams.department_code"
                                                          :faculty_code="queryParams.faculty_code" :multiple="true"
                                                          :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="program_code"
                                                v-slot="{ valid, errors }">
                                <b-form-group class="col-12" :label="$t('program')">
                                    <program-selectbox v-model="queryParams.program_code" :multiple="true"
                                                       :faculty_code="queryParams.faculty_code"
                                                       :department_code="queryParams.department_code"
                                                       :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-5">
                            <ValidationProvider name="student_number"
                                                v-slot="{ valid, errors }">

                                <b-form-group class="col-12" :label="$t('student_number')">
                                    <multi-text v-model="queryParams.student_number" :only-number-and-enter="true"
                                                height="220px"
                                                :validate-error="errors[0]"/>
                                </b-form-group>

                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4 px-0">
                <b-button
                    v-if="checkPermission('studentprogram_updateclasses')"
                    label="start"
                    variant="primary"
                    @click="updateClasses"/>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Services
import StudentProgramService from "@/services/StudentProgramService";

//Components
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import MultiText from "@/components/interactive-fields/MultiText.vue";

//Other
import qs from "qs";

import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        MultiText,
        ProgramSelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            queryParams: {
                student_number: ''
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("class_advancement")
        }
    },
    methods: {
        updateClasses() {

            let config = {
                params: {...this.queryParams,student_number:this.queryParams.student_number.split(',')},
                paramsSerializer: (params) => {
                    return qs.stringify(params, {encode: false})
                }
            }

            this.$swal({
                title: this.$t('attention'),
                text: this.$t('proccess_will_be_started'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    StudentProgramService.updateClasses(config)
                        .then(response => {
                            this.$toast.success(this.$t('proccess_success'));
                            this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.form)
                        })
                }
            })
        }
    }
}
</script>
